.Generator {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #369ac9;
}
.GeneratorInner {
    min-height: 75vh;
    width: 80%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.GeneratorTop {
    min-height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.GeneratorTopCancel {
    height: 100%;
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    right: 0;
}
.GeneratorTitle {
    color: #dbdbdb;
    font-size: 1.5em;
    font-family: 'Poppins Bold';
    width: 100%;
}

.GeneratorMid {
    min-height: 50vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.GeneratorMidCheck {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.GeneratorMidCheckInfo {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; 
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.GeneratorMidCheckInner {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
}
.GeneratorBottom {
    min-height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.GeneratorBottomInner {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.NavigateButton {
    background-color: #dbdbdb;
    color: #369ac9;
    margin: 20px;
}

.InputForm {
    width: 80%;
}
.InputSelect {
    flex: 1;
    margin: 10px !important;
}

.InputSelectOuter {
    width: 50%;
}

.GeneratorAddLayerDiv {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;
}


.LayerContent {
    position: relative;
    width: 100%;
    align-self: center;
    min-height: 50vh;
}

.LayerContent>div {
    position: absolute;
    width: 100%;
    height: 80px;
    transform-origin: 50% 50% 0px;
    border-radius: 5px;
    touch-action: none;
    background: #369ac9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.LayerContentOuter {
    width: 100%;
    align-self: center;
    height: 100%;
}

.SublayerSwiper {
    width: 100%;
    min-height: 45vh;
}

.SublayerSwiperOuter {
    width: 100%;
    min-height: 45vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.SublayerSwiperTop {
    width: 75%;
    min-height: 8vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.SublayerSwiperSubTop {
    width: 75%;
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.SublayerSwiperInner {
    width: 90%;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SublayerSwiperTitle {
    color: #dbdbdb;
    font-size: 1.5em;
    font-family: 'Poppins Bold';
    flex: 1;
}

.GeneratorPropability {
    width: 100%;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SublayerSwiperSubTopSelect {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.LayerSelect {
    width: 300px
}

.ImageDropOuter {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ImageDropButtons {
    width: 10%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.DialogContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #369ac9;
}

.GeneratorMidInit {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.GeneratorImageOuter {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 50px;
    margin-top: 50px;
    position: relative;
    height: 130px;
}

.GeneratorImageInner {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    height: 130px;
    
}

.GeneratorImageOver {
    width: 100%;
    position: absolute; 
    height: 130px;
    background: rgb(54, 154, 201);
    background: linear-gradient(90deg, rgba(54, 154, 201, 1) 0%, rgba(54, 154, 201, 0) 35%, rgba(54, 154, 201, 0) 65%, rgba(54, 154, 201, 1) 100%);
}
.GeneratorImage {
    
    width: auto;
    height: 100px;
    margin: 5px;
}