.Metadata {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #369ac9;
}

.MetadataInner {
    min-height: 75vh;
    width: 80%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.HiddenDiv {
    display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 80%; 
        max-width: 1000px;
}