@font-face {
  font-family: "Poppins";
  src: local("Poppins"), local("Poppins"),
    url("./static/fonts/Poppins.woff2") format("woff2"),
    url("./static/fonts/Poppins.woff") format("woff"),
    url("./static/fonts/Poppins.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins Bold";
  src: local("Poppins Bold"), local("Poppins Bold"),
    url("./static/fonts/Poppins-Bold.woff2") format("woff2"),
    url("./static/fonts/Poppins-Bold.woff") format("woff"),
    url("./static/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

.App {
  text-align: center;
}
