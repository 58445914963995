.PgpTools {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #369ac9;
}

.PgpToolsInner {
    min-height: 75vh;
    width: 80%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    margin-bottom: 10vh;
}