.login {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #369ac9;
}

.login__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #369ac9;
    padding: 30px;
    margin-top: 50px;
}

.login__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
}

.login__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: #369ac9;
    background-color: #dbdbdb;
    cursor: pointer;
}

