.Start {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #369ac9;
    overflow: hidden;
}

.LogoDiv {
    width: 80%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ButtonDiv {
    width: 95%;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    margin-top: 50px;
}

.StartButton {
    padding: 10px;
    margin-bottom: 20px;
    width: 22%;
    border: none;
    color: #369ac9;
    background-color: #dbdbdb;
    cursor: pointer;
    border-radius: 3px;
}

@media only screen and (max-width: 900px) {
    .StartButton {
        width: 30%;
    }
}

@media only screen and (max-width: 700px) {
    .StartButton {
            width: 45%;
        }
}
@media only screen and (max-width: 500px) {
    .StartButton {
        width: 90%;
    }
}